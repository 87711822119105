import classNames from 'classnames';
import React from 'react';
import { Helmet } from 'react-helmet';
import clearPictureImage from '../assets/screenshots/clearPicture.png';
import compareEverythingImage from '../assets/screenshots/compareEverything.png';
import dashboardImage from '../assets/screenshots/dashboard.png';
import groupHoldingsImage from '../assets/screenshots/groupHoldings.png';
import whyChangedImage from '../assets/screenshots/whyChanged.png';
import Layout from '../components/Layout';
import TryNowForm from '../components/TryNowForm';
import styles from './index.module.css';
import mixins from '../mixins.module.css';
import Header from '../components/Header';

interface FeaturesProps {
  children: React.ReactNode;
}

function Features({ children }: FeaturesProps) {
  return (
    <div className={styles.features} id="features">
      <div className={mixins.content}>{children}</div>
    </div>
  );
}

interface FeatureSectionProps {
  children: React.ReactNode;
  image: string;
  title: string;
}

function FeatureSection({ children, image, title }: FeatureSectionProps) {
  return (
    <section className={styles.featureSection}>
      <img src={image} alt="Feature illustration" />
      <div className={styles.content}>
        <h2>{title}</h2>
        {children}
      </div>
    </section>
  );
}

export default function IndexPage() {
  return (
    <>
      <Helmet>
        <title>Wealthie — Modern investment portfolio tracker</title>
      </Helmet>

      <Header logIn variant="hero" />

      <section className={classNames(styles.hero, mixins.content)}>
        <div className={styles.heroText}>
          <h2>Be in charge of&nbsp;your money</h2>
          <p>
            Stocks, funds, cryptocurrencies — all&nbsp;in&nbsp;one&nbsp;place.
          </p>
          <p>
            Know where you stand
            and&nbsp;learn&nbsp;where&nbsp;to&nbsp;go&nbsp;next.
          </p>
          <div className={styles.tryNow}>
            <TryNowForm />
          </div>
        </div>

        <img src={dashboardImage} alt="Wealthie dashboard" />
      </section>

      <Layout variant="feature">
        <Features>
          <FeatureSection
            image={groupHoldingsImage}
            title="Group your holdings"
          >
            <p>
              Create <strong>custom groups</strong> of holdings and compare
              their performance.
            </p>
            <p>
              Separate your funds, stocks, and cryptocurrencies to stay in
              control of <strong>asset allocation</strong>.
            </p>
          </FeatureSection>
          <FeatureSection
            image={compareEverythingImage}
            title="Compare everything with anything"
          >
            <p>
              <strong>Compare</strong> your portfolio and groups against stocks,
              ETFs, and funds.
            </p>
            <p>
              Check <strong>return percentage</strong> or simulate purchases
              based on your cost over time.
            </p>
          </FeatureSection>
          <FeatureSection
            image={clearPictureImage}
            title="Get a clear picture of your contributions"
          >
            <p>
              See <strong>historical transactions</strong> and how they affected
              the value of your portfolio.
            </p>
          </FeatureSection>
          <FeatureSection
            image={whyChangedImage}
            title="Know why things changed"
          >
            <p>
              Investigate spikes, see <strong>historical news headlines</strong>{' '}
              for any of your holdings.
            </p>
          </FeatureSection>
        </Features>
      </Layout>
    </>
  );
}
